/**
 * Production environment object
 */
export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDzOKg1DKEL-I4BhmxLdWAwAyz8-tMr-mM",
    authDomain: "defects-system.firebaseapp.com",
    databaseURL: "https://defects-system.firebaseio.com",
    projectId: "defects-system",
    storageBucket: "defects-system.appspot.com",
    messagingSenderId: "266569484849",
    appId: "1:266569484849:web:f644730093699a74f0e310",
  },
  rollbarKey: "f3ef67222b58466ea463e13b71e6998d",
  apiBaseUrl: "https://defectwise-api-apim.azure-api.net/defectwise-api/v1/",
  apiSubscriptionKey: "870b3f9cb66440c5b0fc16b689d12369",
  appStorageAccount: "https://defectwiseappdata.blob.core.windows.net/",
  reportServerUrl: "https://server.defectwise.com.au/api/v1/",
  /** Not utilised on production */
  reportSubscriptionKey: "",
  /** Google Analytics Ga4 Tracking Tag (PRODUCTION) */
  ga4measurementTag: "G-ZN5NYCEP0C",
  /** Mixpanel Project ID */
  mixpanel: "5d1ee17f86c39ae9948890bb4a4093b4",
  /** Stripe (Production key) */
  stripeKey:
    "pk_live_51Gwf1sL9P7w9VoDKaoREKWT3FPPcuiHElXtAlintWWLWaRAIDc2HxaewshyGJAhmpQ8321WFnIrZjjOcIdTSSAt000SsMR7k0E",
  /**
   * Production products
   * - Keys are production and match with those on the /pricing page
   */
  products: {
    price_1HSAv5L9P7w9VoDKCLRJUfsc: {
      item_id: "price_1HSAv5L9P7w9VoDKCLRJUfsc",
      item_name: "Premium Monthly Subscription",
    },
    price_1HSAv5L9P7w9VoDKsn7aEX6B: {
      item_id: "price_1HSAv5L9P7w9VoDKsn7aEX6B",
      item_name: "Premium Yearly Subscription",
    },
    /** TEAMS - MONTHLY */
    price_1LaugWL9P7w9VoDKJTGfAkDJ: {
      item_id: "price_1LaugWL9P7w9VoDKJTGfAkDJ",
      item_name: "Teams Monthly Subscription",
    },
    /** TEAMS - YEARLY */
    price_1LaugWL9P7w9VoDK1mAldDYX: {
      item_id: "price_1LaugWL9P7w9VoDK1mAldDYX",
      item_name: "Teams Yearly Subscription",
    },
  },
  pricingTiers: {
    /** Premium Monthly */
    price_1HSAv5L9P7w9VoDKCLRJUfsc: [{ quantity: 1, cost: 19 }],
    /** Premium Yearly */
    price_1HSAv5L9P7w9VoDKsn7aEX6B: [{ quantity: 1, cost: 15.16667 }],
    /** TEAMS - MONTHLY */
    price_1LaugWL9P7w9VoDKJTGfAkDJ: [
      { quantity: 5, cost: 19 },
      { quantity: 15, cost: 18 },
      { quantity: 30, cost: 17 },
      { quantity: 100, cost: 16 },
    ],
    /** TEAMS - YEARLY */
    price_1LaugWL9P7w9VoDK1mAldDYX: [
      { quantity: 5, cost: 15.67 },
      { quantity: 15, cost: 14.5 },
      { quantity: 30, cost: 13.5 },
      { quantity: 100, cost: 13 },
    ],
  },
  promoCodes: {
    NEWDEFECT20: "promo_1PNl14L9P7w9VoDKfz3Co2XU",
    // NEWDEFECT20: "0JJPAPt0",
  },
};
