import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

import * as mixpanel from "mixpanel-browser";

@Injectable({
  providedIn: "root",
})
export class MixpanelService {
  /** @ignore */
  constructor() {
    this.init();
  }
  /**
   * Initializes the mixpanel with the given environment and debug flag.
   */
  private init(): void {
    try {
      if (environment.production) {
        mixpanel.init(environment.mixpanel, { debug: !environment.production });
        mixpanel.set_config({ persistence: "localStorage" });
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  /**
   * Identifies a user in Mixpanel if a user ID is provided, otherwise resets the
   * Mixpanel user identity. Catches and logs any exceptions thrown during the
   * process.
   *
   * @param {string | null} userId - The ID of the user to identify in Mixpanel.
   * @return {void} This function does not return anything.
   */
  identifyUser(userId: string | null): void {
    try {
      if (userId) {
        mixpanel.identify(userId);
      } else {
        mixpanel.reset();
      }
    } catch (ex) {
      console.error(ex);
    }
  }
  /**
   * Sets user properties for Mixpanel.
   *
   * @param {_MixpanelAdditionalUserProps} data - an object containing additional user properties
   * @return {void} nothing is returned
   */
  setUserProperties(data: _MixpanelAdditionalUserProps): void {
    try {
      let props: { [key: string]: string | number } = {};
      if (data.plan) {
        props["Plan"] = data.plan;
      }
      if (data.name) {
        props["$name"] = data.name;
      }
      if (data.company) {
        props["Company"] = data.company;
      }
      if (data.tenantId) {
        props["TenantId"] = data.tenantId;
      }
      if (data.email) {
        props["$email"] = data.email;
      }

      // if props is empty return
      if (Object.keys(props).length > 0) {
        mixpanel.people.set(props);
      }
    } catch (ex) {
      console.error(ex);
    }
  }
  /**
   * Sends a Mixpanel event with optional properties.
   *
   * @param {MixpanelEvents} event - The Mixpanel event to track.
   * @param {Object.<string, string>} [props] - Optional properties to include with the event.
   * @return {void} This function does not return a value.
   */
  trackEvent(event: MixpanelEvents, props?: { [key: string]: any }): void {
    try {
      mixpanel.track(event, props);
    } catch (ex) {
      console.error(ex);
    }
  }
}

interface _MixpanelAdditionalUserProps {
  plan?: "Paid" | "Free" | "Client";
  name?: string;
  company?: string;
  tenantId?: number;
  email?: string;
}

type MixpanelEvents =
  | "create_defect"
  | "create_report"
  | "sign_up"
  | "sign_up_client_portal";
